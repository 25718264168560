// Post Object

export enum Service {
    Facebook = "FACEBOOK",
    Twitter = "TWITTER",
    Instagram = "INSTAGRAM",
};

export enum Status {
    Approved = "APPROVED",
    ChangeRequested = "CHANGE_REQUESTED",
    Pending = "PENDING",
};

interface IPost {
    title: string;
    body: string;
    services: Service[];
    status: Status;
}

export default class Post {
    public title: string;
    public body: string;
    public services: Service[];
    public status: Status;

    constructor(post: IPost) {
        this.title = post.title;
        this.body = post.body;
        this.services = post.services;
        this.status = post.status;
    }
};