import React from 'react';

import {
    Typography,
} from '@material-ui/core';

import { UserContext } from '../user/User';

export default () => {
    const {user} = React.useContext(UserContext);

    return <Typography variant="h6">{JSON.stringify(user)}</Typography>;
}