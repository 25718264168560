import React from 'react';

import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    createStyles,
    FormControlLabel,
    Grid,
    makeStyles,
    Switch,
    TextField,
    Theme,
} from '@material-ui/core';

const withStyles = makeStyles((_theme: Theme) => createStyles({
    root: {
        flex: 1,
    },
    card: {
        width: '50%',
    },
}));

export default () => {
    const [faSwitchState, setFaSwitchState] = React.useState(false);
    const [twSwitchState, setTwSwitchState] = React.useState(false);
    const [igSwitchState, setIgSwitchState] = React.useState(false);

    const classes = withStyles();

    return (
        <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.root}>
            <Card
                className={classes.card}>
                <CardHeader
                    title="Request a Post" />

                <CardContent>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center">
                        <TextField
                            fullWidth
                            multiline
                            rows="5"
                            label="Create Post"
                            placeholder="Enter Post Text"
                            margin="normal"
                            variant="outlined"/>
                        
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center">
                            <FormControlLabel
                                label="Facebook"
                                labelPlacement="top"
                                control={
                                    <Switch
                                        checked={faSwitchState}
                                        onChange={() => setFaSwitchState(!faSwitchState)}/>
                                }/>
                            <FormControlLabel
                                label="Twitter"
                                labelPlacement="top"
                                control={
                                    <Switch
                                        checked={twSwitchState}
                                        onChange={() => setTwSwitchState(!twSwitchState)}/>
                                }/>
                            <FormControlLabel
                                label="Instagram"
                                labelPlacement="top"
                                control={
                                    <Switch
                                        checked={igSwitchState}
                                        onChange={() => setIgSwitchState(!igSwitchState)}/>
                                }/>
                        </Grid>
                    </Grid>
                </CardContent>

                <CardActions>
                </CardActions>
            </Card>
        </Grid>
    );
};