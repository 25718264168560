import React from 'react';
import axios from 'axios';
import clsx from 'clsx';

import { useSnackbar } from 'notistack';

import {
    Button,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    createStyles,
    Grid,
    makeStyles,
    TextField,
    Theme,
} from '@material-ui/core';

const withStyles = makeStyles((_theme: Theme) => createStyles({
    grow: {
        width: '100%',
        flexGrow: 1,
    },
    noWrap: {
        flexWrap: 'nowrap',
    },
    cardMargin: {
        marginTop: 32,
    },
    formatHeading: {
        paddingRight: 256,
    },
    formatInput: {
        flexGrow: 1,

        margin: 4,
    },
    formatLink: {
        marginTop: 4,
    },
}));

export default () => {
    const [email, setEmail] = React.useState<string>("");
    const [firstName, setFirstName] = React.useState<string>("");
    const [lastName, setLastName] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");

    const { enqueueSnackbar } = useSnackbar();

    const classes = withStyles();

    const api = axios.create({
        baseURL: "http://localhost:5000",
        timeout: 1000
    })

    const handleSubmit = () => {
        api.put("/user", {
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password
        }).then((response) => enqueueSnackbar('Created new user', {variant: 'success'}));
    };

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center">
            <Card className={classes.cardMargin}>
                <CardHeader
                    className={classes.formatHeading}
                    title="Create User"/>

                <CardContent>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center">
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            className={clsx(classes.formatInput, classes.noWrap)}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                label="First Name"
                                placeholder="First Name"
                                type="text"
                                className={classes.formatInput}
                                onChange={(event) => setFirstName(event.target.value)} />
                            <TextField
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                label="Last Name"
                                placeholder="Last Name"
                                type="text"
                                className={classes.formatInput}
                                onChange={(event) => setLastName(event.target.value)} />
                        </Grid>
                        <Grid
                            item
                            className={clsx(classes.formatInput, classes.grow)}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                label="Email"
                                placeholder="Email"
                                type="email"
                                onChange={(event) => setEmail(event.target.value)} />
                        </Grid>
                        <Grid
                            item
                            className={clsx(classes.formatInput, classes.grow)}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                label="Password"
                                placeholder="Password"
                                type="password"
                                onChange={(event) => setPassword(event.target.value)} />
                        </Grid>
                    </Grid>
                </CardContent>

                <CardActions>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        className={classes.grow}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            className={classes.grow}>
                            <Button
                                color="primary"
                                variant="outlined"
                                className={classes.grow}
                                onClick={handleSubmit}>
                                Create Account
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </Grid>
    );
}