import React from 'react';
import axios, { AxiosInstance } from 'axios';

interface IApiInterface {
    api: AxiosInstance;
}
export const ApiContext = React.createContext<IApiInterface>({api: axios.create({
    baseURL: "http://localhost:5000",
    timeout: 1000,
    withCredentials: true,
})});

export enum Status {
    Success = "success",
    Error = "error",
}