import React from 'react';

import {
    BrowserRouter,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';

import { SnackbarProvider } from 'notistack';
import { Location } from 'history';

import User, { UserContext } from './components/user/User';
import { ApiContext, Status } from './components/utils/Api';
import Navigation from './components/navigation/Navigation';
import Dashboard from './components/dashboard/Dashboard';
import Login from './components/user/Login';
import CreateUser from './components/user/Create';
import RequestPost from './components/post/RequestPost';
import PendingPosts from './components/post/PendingPosts';

const PandaRouter = () => {
    const { api } = React.useContext(ApiContext);

    const [user, setUser] = React.useState<User | undefined>();
    api.get('/user').then((response) => (JSON.parse(response.data).status === Status.Success ? setUser(response.data) : setUser(undefined)));

    return (
        <UserContext.Provider value={{user, setUser}}>
            <BrowserRouter>
                <Switch>
                    <Route path="/" render={({location}: {location: Location}) => {
                        if(!user) {
                            return location.pathname !== "/login" ? <Redirect to="/login" /> : <Route path="/login" component={Login} />;
                        } else {
                            if(location.pathname === "/login") {
                                return <Redirect to="/" />;
                            }
                            return (
                                <Navigation>
                                    <Route path="/" exact component={Dashboard} />
                                    <Route path="/manage_users" component={CreateUser} />
                                    <Route path="/manage_posts" component={PendingPosts} />
                                    <Route path="/request_post" component={RequestPost} />
                                </Navigation>
                            );
                        };
                    }}/>
                </Switch>
            </BrowserRouter>
        </UserContext.Provider>
    )
};

export default () => {
    const { api } = React.useContext(ApiContext);

    return (
        <ApiContext.Provider value={{api}}>
            <SnackbarProvider maxSnack={3}>
                <PandaRouter />
            </SnackbarProvider>
        </ApiContext.Provider>
    );
};