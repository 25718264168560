import React from 'react';
import clsx from 'clsx';
import GoogleLogin from 'react-google-login';

import {
    Button,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    createStyles,
    Grid,
    Link,
    makeStyles,
    TextField,
    Theme,
} from '@material-ui/core';

import User, { UserContext } from "./User";
import { ApiContext } from '../utils/Api';
import { History } from 'history';

const withStyles = makeStyles((_theme: Theme) => createStyles({
    grow: {
        width: '100%',
        flexGrow: 1,
    },
    noWrap: {
        flexWrap: 'nowrap',
    },
    cardMargin: {
        marginTop: 32,
    },
    formatHeading: {
        paddingRight: 256,
    },
    formatInput: {
        flexGrow: 1,

        marginTop: 4,
        marginBottom: 4,
    },
    formatLink: {
        marginTop: 4,
    },
}));

export default ({history}: {history: History}) => {
    const [email, setEmail] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");

    const {setUser} = React.useContext(UserContext);

    const classes = withStyles();

    const { api } = React.useContext(ApiContext);

    const handleEmail = (event: any) => {
        setEmail(event.target.value);
    };

    const handlePassword = (event: any) => {
        setPassword(event.target.value);
    };

    const handleGoogleSignIn = (event: any) => {
    }

    const handleLogin = () => {
        api.post("/auth/login", {
            email: email,
            password: password
        }).then((response) => {
            if (response.status < 300) {
                setUser(new User(response.data));
            }
        });
    };

    const handleForgotPassword = () => {
        //Put in some code to handle forgetting password
    };

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center">
            <Card className={classes.cardMargin}>
                <CardHeader
                    className={classes.formatHeading}
                    title="Login"/>

                <CardContent>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center">
                        <Grid
                            item
                            className={classes.formatInput}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                label="Email"
                                placeholder="Email"
                                type="email"
                                onChange={handleEmail} />
                        </Grid>
                        <Grid
                            item
                            className={classes.formatInput}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                label="Password"
                                placeholder="Password"
                                type="password"
                                onChange={handlePassword} />
                        </Grid>
                    </Grid>
                </CardContent>

                <CardActions>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        className={classes.grow}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            className={clsx(classes.grow, classes.noWrap)}>
                            <GoogleLogin
                                clientId="147048352780-bn3bdhp8kqmei2cuoeieb4vcn0jh6u8d.apps.googleusercontent.com"
                                onSuccess={handleGoogleSignIn}
                                onFailure={handleGoogleSignIn}
                                buttonText=""
                                uxMode="redirect"
                                cookiePolicy={'single_host_origin'}/>
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={handleLogin}>
                                Login
                            </Button>
                        </Grid>
                        <Grid item>
                            <Link
                                component="button"
                                variant="body2"
                                className={classes.formatLink}
                                onClick={handleForgotPassword}>
                                forgot password?
                            </Link>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </Grid>
    );
};