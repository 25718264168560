import React from 'react';
import clsx from 'clsx';

import {
    Button,
    Card,
    CardActions,
    CardContent,
    createStyles,
    makeStyles,
    Theme,
    Typography,
    Grid,
} from '@material-ui/core';

import ThumbUp from '@material-ui/icons/ThumbUp';
import ThumbDown from '@material-ui/icons/ThumbDown';

import Post from '../post/Post';
import {
    FaceBookIcon,
    TwitterIcon,
    InstagramIcon,
} from '../utils/ServiceIcons';

const withStyles = makeStyles((_theme: Theme) => createStyles({
    button: {
        margin: 0,
        color: '#00000077',
    },
    acceptButton: {
        borderRadius: '0 4px 4px 0',

        backgroundColor: '#40ee40ff',
        '&:hover': {
            backgroundColor: '#40ee4077',
        }
    },
    rejectButton: {
        borderRadius: '4px 0 0 4px',

        backgroundColor: '#ff4444ff',
        '&:hover': {
            backgroundColor: '#ff444477',
        }
    },
    card: {
        width: '30%',
        marginBottom: 6,
    },
}));

const ServiceIconMap = {
    'FACEBOOK': <FaceBookIcon />,
    'TWITTER': <TwitterIcon />,
    'INSTAGRAM': <InstagramIcon />,
}

export default ({post}: {post: Post}) => {
    const classes = withStyles();

    return (
        <Card className={classes.card}>
            <CardContent>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    wrap="nowrap">
                    <Typography variant="h6">{post.title}</Typography>

                    <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                        {post.services.map((service => ServiceIconMap[service]))}
                    </Grid>
                </Grid>

                <Grid
                    container
                    justify="flex-start"
                    alignItems="center">
                    {post.body}
                </Grid>
            </CardContent>

            <CardActions>
                <Grid
                    container
                    justify="flex-end"
                    alignItems="center">
                    <Button className={clsx(classes.button, classes.rejectButton)}>
                        <ThumbDown />
                    </Button>
                    <Button className={clsx(classes.button, classes.acceptButton)}>
                        <ThumbUp />
                    </Button>
                </Grid>
            </CardActions>
        </Card>
    );
};