import React from 'react';

export default class User {
    gid: string = "";
    email: string = "";

    constructor(jsonObj?: any) {
        if(jsonObj !== null) {
            this.gid = jsonObj.gid;
            this.email = jsonObj.email;
        }
    }
}

interface IUserContext {
    user?: User;
    setUser?: any;
}
export const UserContext = React.createContext<IUserContext>({});
