import React from 'react';
import clsx from 'clsx';

import {
    Button,
    createStyles,
    makeStyles,
    Theme,
} from '@material-ui/core';

import {
    FaFacebookSquare,
    FaTwitterSquare,
    FaInstagram,
} from 'react-icons/fa';

const withStyles = makeStyles((_theme: Theme) => createStyles({
    icon: {
        fontSize: '1.5rem',
        padding: '0px',
        paddingLeft: '0.5px',
        paddingTop: '0.5px',
        margin: '1px',
        minWidth: '0px',

        color: '#ffffff',
        borderRadius: '-1px',
    },
    facebook: {
        backgroundColor: '#3c5a99',
    },
    twitter: {
        backgroundColor: '#1da1f2',
    },
    instagram: {
        backgroundColor: '#f00075'
    },
}));

export const FaceBookIcon = () => {
    const classes = withStyles();

    return (
        <Button className={clsx(classes.icon, classes.facebook)}>
            <FaFacebookSquare />
        </Button>
    );
};

export const TwitterIcon = () => {
    const classes = withStyles();

    return (
        <Button className={clsx(classes.icon, classes.twitter)}>
            <FaTwitterSquare />
        </Button>
    );
};

export const InstagramIcon = () => {
    const classes = withStyles();

    return (
        <Button className={clsx(classes.icon, classes.instagram)}>
            <FaInstagram />
        </Button>
    );
};