import React from 'react';

import {
    Grid
} from '@material-ui/core';

import {Service, Status} from './Post';
import PostCard from '../utils/PostCard';

export default () => {
    const genFakePosts = () => {
        let returnCards = [];
        for(let i=0; i<10; ++i) {
            returnCards.push(
                <PostCard key={i} post={{
                    title: "Sometitle",
                    body: "some body",
                    services: [Service.Facebook, Service.Twitter, Service.Instagram],
                    status: Status.Pending
                }} />
            );
        }
        return returnCards;
    }

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center">
            {genFakePosts()}
        </Grid>
    );
};