import React from 'react';
import clsx from 'clsx';

import {
    AppBar,
    CssBaseline,
    createStyles,
    Divider,
    Drawer,
    Fab,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Menu,
    MenuItem,
    Theme,
    Toolbar,
    Typography,
} from '@material-ui/core';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AccountIcon from '@material-ui/icons/AccountCircle';
import TuneIcon from '@material-ui/icons/Tune';
import MessageIcon from '@material-ui/icons/Message';
import PaymentIcon from '@material-ui/icons/Payment';
import AddIcon from '@material-ui/icons/Add';

import { Link, LinkProps } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { UserContext } from '../user/User';
import { ApiContext } from '../utils/Api';

import ArrowTooltip from '../utils/ArrowTooltip';

const drawerWidth = 240;

const withStyles = makeStyles((_theme: Theme) => createStyles({
    root: {
        display: 'flex',
    },
    grow: {
        flexGrow: 1,
    },
    link: {
        color: '#ffffff',
        textDecoration: 'none',
    },
    menuButton: {
        marginRight: 5,
    },
    iconShift: {
        marginLeft: 6,
    },
    '@media (max-width: 960px)': {
        menuButton: {
            marginRight: -2,
        },
        iconShift: {
            marginLeft: 0,
        },
    },
    appBar: {
        zIndex: _theme.zIndex.drawer + 1,
        width: `calc(100% - ${_theme.spacing(7)}px)`, [_theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${_theme.spacing(9)}px)`,
        },
        transition: _theme.transitions.create(['width', 'margin'], {
            easing: _theme.transitions.easing.sharp,
            duration: _theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: _theme.transitions.create(['width', 'margin'], {
            easing: _theme.transitions.easing.sharp,
            duration: _theme.transitions.duration.enteringScreen,
        }),
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ..._theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: _theme.spacing(3),
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: _theme.transitions.create('width', {
            easing: _theme.transitions.easing.sharp,
            duration: _theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: _theme.transitions.create('width', {
            easing: _theme.transitions.easing.sharp,
            duration: _theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: _theme.spacing(7) + 1, [_theme.breakpoints.up('sm')]: {
            width: _theme.spacing(9) + 1,
        },
    },
    formatFab: {
        position: 'fixed',
        bottom: _theme.spacing(2),
        right: _theme.spacing(2),
    },
}));

export default (props: any) => {
    const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
    const [navigationOpen, setNavigationOpen] = React.useState<boolean>(false);

    const { setUser } = React.useContext(UserContext);
    const { api } = React.useContext(ApiContext);

    const cookies = new Cookies();

    const classes = withStyles();

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const handleAccount = () => {
        handleMenuClose();
    };

    const handleLogout = () => {
        api.post('/auth/logout')
            .then(() => {
                cookies.remove('session');
                setUser(undefined);
            });
    };

    const AdapterLink = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
        <Link innerRef={ref as any} {...props} />
    ));

    const TooltipItem = ({link, title, icon}: {link: string, title: string, icon: string}) => {
        const IconMap: any = {
            'tune_icon': (<TuneIcon />),
            'message_icon': (<MessageIcon />),
            'payment_icon': (<PaymentIcon />),
        };

        if(navigationOpen) {
            return (
                <ListItem
                    button
                    to={link}
                    component={AdapterLink}>
                    <ListItemIcon className={clsx(classes.iconShift)}>{IconMap[icon]}</ListItemIcon>
                    <ListItemText primary={title}/>
                </ListItem>
            );
        }
        else {
            return (
                <ArrowTooltip
                    placement="right"
                    title={title}>
                    <ListItem
                        button
                        to={link}
                        component={AdapterLink}>
                        <ListItemIcon className={clsx(classes.iconShift)}>{IconMap[icon]}</ListItemIcon>
                        <ListItemText primary={title}/>
                    </ListItem>
                </ArrowTooltip>
            );
        }
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: navigationOpen,
                })}>
                <Toolbar>
                    <Link className={classes.link} to="/"><Typography variant="h6">Panda Post</Typography></Link>

                    <div className={classes.grow} />
                    
                    <IconButton
                        color="inherit"
                        aria-owns={menuAnchor ? 'simple-menu' : undefined}
                        aria-haspopup={true}
                        aria-label="Account Settings"
                        onClick={handleMenuOpen}>
                        <AccountIcon />
                    </IconButton>

                    <Menu
                        anchorEl={menuAnchor}
                        open={Boolean(menuAnchor)}
                        onClose={handleMenuClose}>
                        <MenuItem onClick={handleAccount}>My account</MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>

            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: navigationOpen,
                    [classes.drawerClose]: !navigationOpen,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: navigationOpen,
                        [classes.drawerClose]: !navigationOpen,
                    }),
                }}
                open={navigationOpen}>
                <div className={classes.toolbar}>
                    <IconButton
                        color="inherit"
                        aria-label="Open Menu"
                        className={clsx(classes.menuButton)}
                        onClick={() => setNavigationOpen(!navigationOpen)}>
                        {navigationOpen? (<ChevronLeftIcon />) : (<ChevronRightIcon />)}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <TooltipItem link="/manage_users" title="Manage Users" icon="tune_icon" />
                    <TooltipItem link="/manage_posts" title="Manage Posts" icon="message_icon" />
                    <TooltipItem link="/other_thing" title="Manage Thing" icon="payment_icon" />
                </List>
            </Drawer>

            <main className={classes.content}>
                <div className={classes.toolbar} />
                {props.children}
            </main>

            <Fab
                color="primary"
                className={classes.formatFab}
                component={AdapterLink}
                to="/request_post">
                <AddIcon />
            </Fab>
        </div>
    );
}